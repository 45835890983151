.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

p {
  color: black;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hidden {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
}

@keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

a {
  color: blue;
  text-decoration: none;
}

.nav a {
  color: black;
  text-decoration: none;
}

.besokare p {
  max-width: 700px;
}

.besokare table td,
.hitta table td {
  padding-right: 20px;
  padding-bottom: 20px;
}

.info {
  color: black;
  width: 900px;
  max-width: 95%;
}

.info img {
  text-align: center;
}

.info a {
  padding: 0px;
}

.pEntry {
  width: auto;
  position: relative;
}
/* Entry head */
.pSuperHead,
.pHead {
  padding: 3px 0px 3px;
}
.pHead {
  color: black;
  margin-bottom: 5px;
  /* border-top-width: 1px;*/
}
.pDate,
.pTitle,
.pCost,
.pDateSmall,
.pTitleSmall,
.pTitleWide {
  /* float: left;*/
  margin: 0px;
  padding: 0px;
  padding: 0px 5px 0px;
  color: black;
  /* color: #000000;*/
}
.pDate,
.pDateSmall {
  width: auto;
  /* text-align: right; */
  white-space: nowrap;
}
.pDate,
.pTitle,
.pTitleWide {
  font-size: 14pt;
  padding-top: 12px;
  font-weight: bold;
}
.pDateSmall,
.pTitleSmall,
.pTitleWideSmall {
  font-size: 11pt;
  font-weight: bold;
  color: #000;
  color: black;
}
.pTitle,
.pTitleSmall {
  width: auto;
  font-size: 40px;
  /* text-align: left; */
}
.image-hover-opacity:hover {
  opacity: 0.85;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  transition: 0.5s ease;
}

.pImg {
  height: auto;
  max-width: 95%;
  vertical-align: middle;
  display: block;
  margin: 0 auto;
  aspect-ratio: auto 990 / 660;
  width: 990px;
  border-radius: 20px;
  animation: fade-in 0.5s;
}

.pImgTwo {
  max-width: 100%;
  vertical-align: middle;
  margin: 0 auto;
  width: 450px;
  border-radius: 20px;
  animation: fade-in 0.5s;
}

table.program-tabell-bilder {
  max-width: 95%;
}

a:hover {
  opacity: 0.85;
}

.pImg:hover img {
  opacity: 0.85;
}

.pTitleWide,
.pTitleWideSmall {
  width: 730px;
  text-align: left;
}
.pTime {
  /*font-size: 9pt;*/
  font-weight: normal;
  width: 140px;
  font-size: 20px;
  color: black;
  /* text-align: right; */
  white-space: nowrap;
  /*position: relative;*/
  margin-top: 0px;
  /*line-height: 95%; */
}

.pCost {
  /*font-size: 9pt;*/
  font-weight: lighter;
  width: 140px;
  text-align: center;
  color: black;
  opacity: 0.8;
  /* text-align: right; */
  white-space: nowrap;
  /*position: relative;*/
  margin-top: 0px;
  /*line-height: 95%; */
}

.pCost A:link,
.pCost A:visited,
.pCost A:active,
.pCost A:hover {
  color: #000;
  text-decoration: none;
}

table.programOverView {
  color: black;
  text-align: left;
  border-collapse: collapse;
  width: 95%;
  max-width: 600px;
}

.programOverView tr td {
  padding-left: 10px;
  border: collapse;
  padding-right: 10px;
}

table.programOverView tr {
  text-align: center;
}

table.programOverView img {
  padding: 10px;
  border-radius: 20px;
  border: 0px solid white;
}

table.programOverView th {
  text-align: center;
}

table.programArchiveOverView {
  color: black;
  text-align: left;
  border-collapse: collapse;
  width: 95%;
  max-width: 750px;
}

.programArchiveOverView tr td {
  padding-left: 10px;
  border: collapse;
  padding-right: 10px;
  max-width: 100px;
}

table.programArchiveOverView tr {
  text-align: center;
}

table.programArchiveOverView img {
  border-radius: 10px;
  padding: 5px;
}

table.programArchiveOverView th {
  text-align: center;
}

table.bottom-links tr td {
  padding-left: 10px;
  border: collapse;
  padding-right: 10px;
}

td.overviewTitle {
  max-width: 150px;
  padding: 10px;
}

table.archiveOverviewTable {
  width: 95%;
}

div.archiveOverviewTable {
  font-size: 30px;
  max-width: 300px;
}

table.archiveOverviewTable tr td {
  max-width: "100px";
}

div.padBottom {
  padding-bottom: 100px;
}

div.padBottomLight {
  padding-bottom: 70px;
}

div.archiveChild {
  width: 95%;
  max-width: 600px;
  float: center;
  text-shadow:
    0.01em 0 black,
    0 0.01em black,
    -0.01em 0 black,
    0 -0.01em black;
}

img.archiveImage {
  width: 100%;
  max-width: 400px;
}

img.archiveImageDouble {
  width: 50%;
  max-width: 200px;
}

/* Legacy */
.pBody {
  width: 95%;
  max-width: 468px;
  float: center;
  font-family: "Roboto Mono", monospace;
}

.clear {
  clear: both;
}

.pSlotBigL,
.pSlotSmallL,
.pSlotBigR,
.pSlotSmallR,
.pSlotWide {
  margin: 0px;
}
.pSlotBigL,
.pSlotSmallL {
  text-align: right;
  width: 120px;
  padding: 0px 0px 5px 5px;
  float: left;
}
.pSlotBigL {
  font-size: 11pt;
  font-weight: bold;
  color: black;
}
.pSlotSmallL {
  font-size: 9pt;
  font-weight: normal;
  color: black;
  font-weight: bold;
}
.pSlotBigR,
.pSlotSmallR {
  width: 295px;
  padding: 0px 5px 5px 0px;
  float: right;
}
.pSlotBigR {
  font-size: 11pt;
  font-weight: bold;
  color: black;
  text-align: left;
}
.pSlotSmallR {
  font-size: 9pt;
  font-weight: normal;
  color: black; /* padding: 0px 5px 0px;*/
  /* margin-top: 0px;*/
}
.pSlotSmallR P {
  margin-bottom: 5px;
  margin-top: 0px; /**/
}
.pSlotSmallR P:first {
  margin-top: 0px;
}
.pSlotWide {
  text-align: left;
  width: 740px;
  padding: 0px;
  float: left;
  font-size: 9pt;
  font-weight: normal;
  color: #000;
}

.pSlotWide P {
  margin-top: 0px;
  margin-bottom: 5px;
}

table.program-tabell {
  max-width: 95%;
  padding-top: 10px;
}

table.program-tabell > tr > td {
  padding-right: 10px;
  padding-bottom: 4px;
  max-width: 200px;
}

table.program-tabell > tr > td small {
  font-size: 12px;
}

td.liten-text {
  font-weight: lighter;
  font-size: 12px;
}

small.cafe-ansvar-text {
  font-weight: lighter;
}

/* Animation for the links */
.nav td {
  opacity: 0; /* Initially invisible */
  transform: translateY(20px); /* Start position */
  animation: slideIn 0.5s ease-out forwards;
}

/* Spread animation for each link */
.nav td:nth-child(1) {
  animation-delay: 0.1s;
}
.nav td:nth-child(2) {
  animation-delay: 0.2s;
}
.nav td:nth-child(3) {
  animation-delay: 0.3s;
}
.nav td:nth-child(4) {
  animation-delay: 0.4s;
}
.nav td:nth-child(5) {
  animation-delay: 0.5s;
}
.nav td:nth-child(6) {
  animation-delay: 0.6s;
}
.nav td:nth-child(7) {
  animation-delay: 0.7s;
}
.nav td:nth-child(8) {
  animation-delay: 0.8s;
}
.nav td:nth-child(9) {
  animation-delay: 0.9s;
}
.nav td:nth-child(10) {
  animation-delay: 1s;
}

/* Keyframe animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hover effect for the links */
.nav a {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
  text-align: center;
  transition:
    color 0.3s ease,
    transform 0.3s ease;
}

.nav a:hover {
  color: #007bff; /* Highlight color */
  transform: scale(1.2);
}
